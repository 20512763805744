html {
  height: -webkit-fill-available;
  width: 100%;
}

body {

  height: -webkit-fill-available;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #151515;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #444444;
}